/* eslint-disable @typescript-eslint/no-unused-expressions */
export const handleListElementsTableOfContent = (
  mainContentRef: React.MutableRefObject<HTMLDivElement | null>,
  tableOfContentsRef: React.MutableRefObject<HTMLDivElement | null>,
  initialPositionConditionTop = -150,
  initialPositionConditionBottom = 300,
  liElementsSelector = 'li'
): void => {
  const currentScrollPos = window.scrollY
  const elements = mainContentRef.current?.querySelectorAll(
    '.product--information--main--content, .product--information--main--faqs'
  )
  const liElements = tableOfContentsRef.current?.getElementsByTagName(liElementsSelector)
  if (!elements || !liElements) return
  elements.forEach((element) => {
    const item = element as HTMLDivElement
    if (
      item.offsetTop - currentScrollPos <= -initialPositionConditionTop ||
      item.offsetTop - currentScrollPos >= initialPositionConditionBottom
    )
      return
    for (const liElement of liElements) {
      'list-item-' + element.id === `${liElement.id}-box`
        ? liElement.classList.add('inView')
        : liElement.classList.remove('inView')
    }
  })
}
