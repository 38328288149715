const widthTable = 720
const widthDesktop = 960

enum DeviceType {
  mobile = 'Mobile',
  tablet = 'Tablet',
  desktop = 'Desktop'
}

const getDeviceWindowSize = (): {
  viewportWidth: number
  viewportHeight: number
  viewportDimensions: string
  deviceType: string
} | null => {
  if (typeof window === 'undefined') return null

  const viewportWidth = window.innerWidth
  const viewportHeight = window.innerHeight
  let deviceType

  if (viewportWidth < widthTable) {
    deviceType = DeviceType.mobile
  } else if (viewportWidth >= widthTable && viewportWidth < widthDesktop) {
    deviceType = DeviceType.tablet
  } else {
    deviceType = DeviceType.desktop
  }

  return {
    viewportWidth,
    viewportHeight,
    viewportDimensions: viewportWidth + ' x ' + viewportHeight,
    deviceType
  }
}

export { getDeviceWindowSize }
