export interface BusinessHoursProps {
  from: {
    hour: number
    minute: number
  }
  to: {
    hour: number
    minute: number
  }
}
export interface BusinessProps {
  Monday?: BusinessHoursProps
  Tuesday?: BusinessHoursProps
  Wednesday?: BusinessHoursProps
  Thursday?: BusinessHoursProps
  Friday?: BusinessHoursProps
  Saturday?: BusinessHoursProps
  Sunday?: BusinessHoursProps
}
const daysWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

interface ValidateDateGmtProps {
  businessHoursConfig: BusinessProps
  dateNow?: Date
}

export const validateDateGmt = ({ businessHoursConfig, dateNow = new Date() }: ValidateDateGmtProps): boolean => {
  const dateUTC = dateNow.toUTCString()
  const dateNewUTC = new Date(dateUTC)
  const dayName = daysWeek[dateNewUTC.getDay()]

  const dataDayHours = businessHoursConfig[dayName]

  if (
    Number.isInteger(dataDayHours?.from.hour) &&
    Number.isInteger(dataDayHours?.from.minute) &&
    Number.isInteger(dataDayHours?.to.hour) &&
    Number.isInteger(dataDayHours?.to.minute)
  ) {
    const dateNewUTCInitial = new Date(dateUTC)
    const dateNewUTCClose = new Date(dateUTC)

    dateNewUTCInitial.setUTCHours(dataDayHours.from.hour)
    dateNewUTCInitial.setUTCMinutes(dataDayHours.from.minute)

    dateNewUTCClose.setUTCHours(dataDayHours.to.hour)
    dateNewUTCClose.setUTCMinutes(dataDayHours.to.minute)

    if (dataDayHours.from.hour > dataDayHours.to.hour) {
      if (dateNewUTC.getTime() > dateNewUTCInitial.getTime()) {
        return true
      } else {
        const dayBefore = dateNewUTC.getDay() === 0 ? 6 : dateNewUTC.getDay() - 1
        const dayNameBefore = daysWeek[dayBefore]
        const dataDayHoursBefore = businessHoursConfig[dayNameBefore]

        if (
          Number.isInteger(dataDayHoursBefore?.from.hour) &&
          Number.isInteger(dataDayHoursBefore?.from.minute) &&
          Number.isInteger(dataDayHoursBefore?.to.hour) &&
          Number.isInteger(dataDayHoursBefore?.to.minute)
        ) {
          dateNewUTCInitial.setUTCHours(dataDayHoursBefore.from.hour)
          dateNewUTCInitial.setUTCMinutes(dataDayHoursBefore.from.minute)

          dateNewUTCClose.setUTCHours(dataDayHoursBefore.to.hour)
          dateNewUTCClose.setUTCMinutes(dataDayHoursBefore.to.minute)

          if (dateNewUTC.getTime() < dateNewUTCInitial.getTime() && dateNewUTC.getTime() < dateNewUTCClose.getTime()) {
            return true
          }
        }
      }
    } else {
      if (dateNewUTC.getTime() > dateNewUTCInitial.getTime() && dateNewUTC.getTime() < dateNewUTCClose.getTime()) {
        return true
      }
    }
  }
  return false
}
