/* eslint-disable @typescript-eslint/no-unused-expressions */
export function isValidDate(date: Date): boolean {
  return !isNaN(date.getTime())
}

export const toDate = (date?: string): Date => {
  let _date = null
  date ? (_date = new Date(date)) : (_date = new Date())
  if (!isValidDate(_date)) {
    throw new Error('Invalid Date')
  }
  return _date
}
