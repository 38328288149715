type Keys = 'USER_EMAIL'

type STRAPI_TEXT_VARIABLES = {
  [key in Keys]: string
}

function replaceValues(
  str: string | null | undefined,
  valuesToReplace: Partial<STRAPI_TEXT_VARIABLES>
): string | undefined {
  if (str === undefined || str === null || typeof str !== 'string') return undefined
  return str.replace(/{{(\w+)}}/g, (valueFromStrapiMatch, key: Keys) => {
    return valuesToReplace[key] ?? valueFromStrapiMatch
  })
}

export { replaceValues }
