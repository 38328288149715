module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LawDistrict","short_name":"LawDistrict","start_url":"/","background_color":"#fff","theme_color":"#05646E","display":"standalone","icon":"src/assets/images/favicon/favicon-lawdistrict.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"site":"datadoghq.eu","sampleRate":100,"service":"legal-web","enabled":true,"env":"stage","version":"1.0.0","trackInteractions":true,"rum":{"applicationId":"844ad292-0cd8-45ca-86a2-b4d871742398","clientToken":"pubc318699bc0615b4065060437118a9435"},"logs":{"clientToken":"pubc318699bc0615b4065060437118a9435"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
