import { createContext, useContext } from 'react'

import { type IConfig, getConfigs } from './IConfig'

const ConfigContext = createContext<IConfig>(getConfigs())

export function useConfigs(): IConfig {
  return useContext(ConfigContext)
}

export default ConfigContext
