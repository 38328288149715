/* eslint-disable @typescript-eslint/no-explicit-any */

import { sendAmplitudeData } from '@legal/shared/amplitude'

const onClickAmplitudeEventDownloadButtons = (
  data: object | any,
  documentType?: string,
  categoryProduct?: string
): void => {
  const eventProps = {
    document_type: documentType,
    category_product: categoryProduct,
    format: data?.format,
    source: data?.source
  }

  sendAmplitudeData(data.eventName, eventProps)
}

export { onClickAmplitudeEventDownloadButtons }
