import { type FormatTypes } from './FormatTypes'
import { type SourceTypes } from './SourceTypes'
import { sendAmplitudeData } from './amplitude'

interface SendEventDownloadFileProps {
  format: FormatTypes
  source: SourceTypes
  eventName: string
  documentType: string
  categoryProduct?: string
}

const sendAmplitudeEventDownloadFile = ({
  format,
  source,
  eventName,
  documentType
}: SendEventDownloadFileProps): void => {
  const eventProps = {
    document_type: documentType,
    format,
    source
  }
  sendAmplitudeData(eventName, eventProps)
}

export { sendAmplitudeEventDownloadFile }
