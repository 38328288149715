exports.components = {
  "component---src-templates-about-us-template-tsx": () => import("./../../../src/templates/AboutUsTemplate.tsx" /* webpackChunkName: "component---src-templates-about-us-template-tsx" */),
  "component---src-templates-all-documents-template-tsx": () => import("./../../../src/templates/AllDocumentsTemplate.tsx" /* webpackChunkName: "component---src-templates-all-documents-template-tsx" */),
  "component---src-templates-contact-us-template-tsx": () => import("./../../../src/templates/ContactUsTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-us-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/HomeTemplate.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-legal-template-tsx": () => import("./../../../src/templates/LegalTemplate.tsx" /* webpackChunkName: "component---src-templates-legal-template-tsx" */),
  "component---src-templates-page-404-template-tsx": () => import("./../../../src/templates/Page404Template.tsx" /* webpackChunkName: "component---src-templates-page-404-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/ProductTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

