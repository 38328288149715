/* eslint-disable @typescript-eslint/no-explicit-any */

import { Base64DecoderService } from '../decoder/Base64DecoderService'
import { Base64EncoderService } from '../encoder/Base64EncoderService'
import { DataStore } from '../datastore/DataStore'

export class AbstractCookie extends DataStore {
  encoderService: Base64EncoderService
  decoderService: Base64DecoderService

  constructor(cookieDataObject: { cookieName: string; expirationDate?: Date }) {
    super({ data: cookieDataObject })
    this.encoderService = new Base64EncoderService()
    this.decoderService = new Base64DecoderService()
  }

  read(key: string): string {
    return this._getCookie()[key]
  }

  clear(): void {
    if (typeof document !== 'undefined') {
      document.cookie = `${this._data.cookieName}=;path=/;domain=${process.env.DOMAIN};expires=Thu, 01 Jan 1970 00:00:01 GMT;`
    }
  }

  write({ key, data }: { key: string; data: string }): void {
    if (typeof document !== 'undefined') {
      const cookie = this._getCookie()
      cookie[key] = data

      const decodedCookieValue = JSON.stringify(cookie)
      const encodedCookieValue = this.encoderService.encode(decodedCookieValue)
      const cookieName = this._data.cookieName
      const expirationDate = this._data.expirationDate
      const expirationDateString = expirationDate ? ';expires=' + expirationDate.toUTCString() + ';' : ''

      document.cookie = `${cookieName}=${encodedCookieValue};path=/;domain=${process.env.DOMAIN}${expirationDateString}`
    }
  }

  _getCookie(): any {
    if (typeof document !== 'undefined') {
      const cookieName = this._data.cookieName
      const value = '; ' + document.cookie
      const parts = value.split('; ' + cookieName + '=')

      if (parts.length === 2) {
        const encodedCookieValue = parts.pop()?.split(';').shift()
        const decodedCookieValue = this.decoderService.decode(encodedCookieValue ?? '')
        return JSON.parse(decodedCookieValue)
      }
    }

    return {}
  }
}
