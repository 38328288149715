import { AbstractCookie } from './AbstractCookie'
import { setEnvironmentPrefixToCookie } from '../../utils/setEnvironmentPrefixToCookie'

export class TestABCookie extends AbstractCookie {
  constructor(cookieName: string) {
    super({ cookieName: setEnvironmentPrefixToCookie(cookieName) })
  }

  get value(): string {
    return this.read('value')
  }

  set value(value) {
    this.write({ key: 'value', data: value })
  }
}
