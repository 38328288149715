import { AbstractCookie } from './AbstractCookie'
import { getDomain } from '../../utils/getDomain'

export class ReferrerCookie extends AbstractCookie {
  constructor() {
    super({ cookieName: 'referrer' })
  }

  get referrerLast(): string {
    return this.read('referrerLast')
  }

  get referrerCurrent(): string {
    return this.read('referrerCurrent')
  }

  getReferrer(): string {
    const last = this.referrerLast
    const current = this.referrerCurrent
    if (last && current) {
      return current === location.href ? last : current
    }
    return document.referrer
  }

  setReferrer(): void {
    this.write({
      key: 'referrerLast',
      data: this.referrerCurrent || null,
      domain: getDomain()
    })
    this.write({
      key: 'referrerCurrent',
      data: location.href,
      domain: getDomain()
    })
  }
}
