/* eslint-disable @typescript-eslint/no-unused-vars */

import { type GatsbySSR, Script } from 'gatsby'
import React from 'react'

import {
  type BusinessHoursProps,
  type BusinessProps,
  validateDateGmt
} from '@legal/shared/utils/validateDateGmt/validateDateGmt'
import { CookiePolicyLayout } from './src/components/layout/CookiePolicyLayout/CookiePolicyLayout'
import { getConfigs } from './src/modules/core/configs/IConfig'

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element, props }) => {
  const { FRESHCHAT_CONFIG, HAS_TRUSTPILOT } = getConfigs()

  return (
    <>
      {HAS_TRUSTPILOT && (
        <Script
          id='trustpilot-script'
          type='text/javascript'
          src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
          async
        />
      )}

      {FRESHCHAT_CONFIG.ENABLED && props.location.pathname === '/contact-us/' && (
        <>
          <Script
            id='fcPreChatForm'
            src='https://snippets.freshchat.com/js/fc-pre-chat-form-v2.min.js'
            onLoad={() => {
              window.preChatTemplate = {
                heading: 'Chat with Us',
                mainbgColor: '#078080',
                maintxColor: '#fff',
                textBanner:
                  "We can't wait to talk to you. But first, please take a couple of moments to tell us a bit about yourself.",
                SubmitLabel: 'Start Chat',
                fields: {
                  field1: {
                    error: 'Please Enter a valid name',
                    fieldId: 'name',
                    label: 'Name',
                    required: 'yes',
                    type: 'text'
                  },
                  field2: {
                    error: 'Please Enter a valid Email',
                    fieldId: 'email',
                    label: 'Email',
                    required: 'yes',
                    type: 'email'
                  }
                }
              }
              window.fcSettings = {
                token: FRESHCHAT_CONFIG.TOKEN,
                host: FRESHCHAT_CONFIG.HOST,
                widgetUuid: FRESHCHAT_CONFIG.WIDGET_ID,
                config: {
                  content: {
                    actions: {
                      tab_chat: 'Chat'
                    },
                    headers: {
                      chat: 'Chat With Us'
                    }
                  },
                  cssNames: {
                    expanded: 'custom_fc_expanded',
                    widget: 'custom_fc_frame'
                  }
                },
                onInit: function () {
                  window.fcPreChatform.fcWidgetInit(window.preChatTemplate)
                }
              }
            }}
          ></Script>
          <Script
            src='https://wchat.eu.freshchat.com/js/widget.js'
            onLoad={() => {
              window.fcWidget.on('widget:loaded', function (resp) {
                window.fcWidget.conversation.setConversationProperties({
                  cf_customer_url: window.location.href
                })
              })

              const hoursBusiness: BusinessHoursProps = {
                from: {
                  hour: 12,
                  minute: 0
                },
                to: {
                  hour: 2,
                  minute: 0
                }
              }
              const businessHoursConfig: BusinessProps = {
                Monday: hoursBusiness,
                Tuesday: hoursBusiness,
                Wednesday: hoursBusiness,
                Thursday: hoursBusiness,
                Friday: hoursBusiness,
                Saturday: hoursBusiness,
                Sunday: hoursBusiness
              }

              const businessInTime = validateDateGmt({ businessHoursConfig })
              if (!businessInTime) {
                document.body.classList.add('freshChat_hide')
              }
            }}
          ></Script>
        </>
      )}
      <CookiePolicyLayout {...props}>{element}</CookiePolicyLayout>
    </>
  )
}
