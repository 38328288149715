export function writeCookie<T>({
  data,
  cookieName,
  expirationDate
}: {
  data: T
  cookieName: string
  expirationDate?: Date
}): void {
  if (typeof document === 'undefined' || data === undefined) return
  const stringCookie = JSON.stringify(data)
  const encodedCookie = btoa(stringCookie)
  const expirationDateString = expirationDate ? `;expires=${expirationDate.toUTCString()};` : ''
  document.cookie = `${cookieName}=${encodedCookie};path=/;domain=${process.env.DOMAIN}${expirationDateString}`
}

export function clearCookie(cookieName: string): void {
  if (typeof document !== 'undefined') {
    document.cookie = `${cookieName}=;path=/;domain=${process.env.DOMAIN};expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  }
}

export function getCookie<T>(cookieName: string): T | undefined {
  if (typeof document === 'undefined') return
  const value = '; ' + document.cookie
  const parts = value.split('; ' + cookieName + '=')
  if (parts.length !== 2) return
  const encodedCookieValue = parts.pop()?.split(';').shift()
  if (!encodedCookieValue) return
  const decodedCookieValue = atob(encodedCookieValue)
  return JSON.parse(decodedCookieValue)
}
