import { logError, logInfo } from '@legal/shared/logger/logger'
import { HttpError } from './http-error'
import { type HttpParams } from './http-params'
import { type HttpStatusCode } from './http-status-code'

export interface HttpClientResponse<Result> {
  result: Result
  status: HttpStatusCode
  headers: HttpClientHeaders
  options: RequestInit
}

export type HttpClientHeaders = Record<string, string | number | boolean>

export interface HttpClientOptions {
  baseUrl: string
  defaults?: RequestInit
}

const defaultOptions = {
  baseUrl: '',
  hooks: { before: [], after: [] }
}

const logEndpointSuccess = (endpoint: string, response?: object): void => {
  logInfo(`Endpoint: ${endpoint}`, response)
}

const logEndpointError = (endpoint: string, response?: object): void => {
  logError(`Endpoint: ${endpoint}`, response)
}

export default class HttpClient {
  private readonly defaultHeaders = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json'
  })

  static create({ baseUrl = defaultOptions.baseUrl, defaults }: HttpClientOptions = defaultOptions): HttpClient {
    const newBaseUrl = this.cleanBaseUrl(baseUrl)

    return new HttpClient({
      baseUrl: newBaseUrl,
      defaults
    })
  }

  private constructor(private readonly options: HttpClientOptions) {}

  static cleanBaseUrl(baseUrl: string): string {
    let newBaseUrl = baseUrl
    if (newBaseUrl.endsWith('/')) {
      newBaseUrl = newBaseUrl.slice(0, newBaseUrl.length - 1)
    }

    return newBaseUrl
  }

  private async sendRequest<Result>(
    url: string,
    options: RequestInit,
    httpParams?: HttpParams
  ): Promise<HttpClientResponse<Result>> {
    const request = this.getRequest(url, httpParams)
    const response = await fetch(request, {
      ...this.options.defaults,
      ...options
    })

    const responseResult = new Promise<HttpClientResponse<Result>>((resolve, reject) => {
      this.getResponse(response, options)
        .then((responseData) => {
          const responseResult = responseData as HttpClientResponse<Result>

          if (responseResult.status >= 400) {
            logEndpointError(url, responseResult)
            reject(responseResult)
            return response
          }

          logEndpointSuccess(url)
          resolve(responseResult)
        })
        .catch((error: object | undefined) => {
          logEndpointError(url, error)
          reject(error)
        })
    })

    return await responseResult
  }

  private getRequest(url: string, httpParams: HttpParams | undefined): Request {
    return new Request(this.getUrl(url, httpParams), {
      headers: this.defaultHeaders
    })
  }

  private getUrl(url: string, params?: HttpParams): string {
    let fullUrl = this.options.baseUrl === '' ? url : this.options.baseUrl + '/' + url

    if (params !== undefined) {
      fullUrl += `?${params.toString()}`
    }
    return new URL(fullUrl).toString()
  }

  private getParsedBody<Body>(body: Body): string {
    return JSON.stringify(body)
  }

  private async getResponse<Result>(response: Response, options: RequestInit): Promise<HttpClientResponse<Result>> {
    if (!response.ok) {
      const resultAsString = await response.text()
      let result = {}
      try {
        result = resultAsString.length !== 0 ? JSON.parse(resultAsString) : undefined
      } catch (exceptionVar) {
        // eslint-disable-next-line no-console
        console.info('info exception response http', exceptionVar)
      }

      throw new HttpError({
        status: response.status,
        message: response.statusText,
        result
      })
    }

    const resultAsString = await response.text()
    const result = resultAsString.length !== 0 ? JSON.parse(resultAsString) : undefined

    const headers: HttpClientHeaders = {}

    response.headers.forEach((value, key) => {
      headers[key] = value
    })

    return {
      result: result as Result,
      headers,
      options,
      status: response.status
    }
  }

  async get<Result>(url: string, httpParams?: HttpParams): Promise<HttpClientResponse<Result>> {
    return await this.sendRequest(url, { method: 'GET' }, httpParams)
  }

  async post<Body, Result = void>(
    url: string,
    body?: Body,
    httpParams?: HttpParams
  ): Promise<HttpClientResponse<Result>> {
    return await this.sendRequest(url, { method: 'POST', body: this.getParsedBody(body) }, httpParams)
  }

  async put<Body, Result = void>(
    url: string,
    body?: Body,
    httpParams?: HttpParams
  ): Promise<HttpClientResponse<Result>> {
    return await this.sendRequest(url, { method: 'PUT', body: this.getParsedBody(body) }, httpParams)
  }

  async delete<Result = void>(url: string, httpParams?: HttpParams): Promise<HttpClientResponse<Result>> {
    return await this.sendRequest(url, { method: 'DELETE' }, httpParams)
  }
}
