import { datadogLogs } from '@datadog/browser-logs'

const logInfo = (text: string, data?: object): void => {
  if (data) {
    datadogLogs.logger.info(text, data)
    return
  }

  datadogLogs.logger.info(text)
}

const logError = (text: string, data?: object): void => {
  // eslint-disable-next-line no-console
  console.error('Error', text, data)
  if (data) {
    datadogLogs.logger.error(text, data)
    return
  }

  datadogLogs.logger.error(text)
}

export { logInfo, logError }
