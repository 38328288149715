import { datadogLogs } from '@datadog/browser-logs'

import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { type GeoLocation } from '@legal/customer'
import { getConfigs } from '@legal/core/configs'

export async function GetCustomerGeoLocation(): Promise<HttpClientResponse<GeoLocation>> {
  const { API_CONFIG } = getConfigs()
  const http = HttpClient.create({
    baseUrl: API_CONFIG.PREFIX
  })

  const myPromise = new Promise<HttpClientResponse<GeoLocation>>((resolve, reject) => {
    http
      .get('customer/geoip')
      .then((response) => {
        const responseData = response as HttpClientResponse<GeoLocation>
        datadogLogs.logger.info('GetCustomerGeoLocation', responseData)
        resolve(responseData)
      })
      .catch((error: object | undefined) => {
        datadogLogs.logger.error('GetCustomerGeoLocation', error)
        reject(error)
      })
  })

  return await myPromise
}
