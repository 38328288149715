interface StrapiConfig {
  URL: string
  TOKEN: string
  DOMAIN: string
  LOCALE: string
}

interface ApiConfig {
  PREFIX: string
  SECRET_KEY: string
}

interface SiteMetadata {
  COMPANY_NAME: string
  COMPANY_NAME_TWO: string
  COMPANY_NAME_THREE: string
  SITE_EMAIL: string
  SITE_NAME: string
  SITE_CIF: string
  SITE_EIN_TWO: string
  SITE_EIN_THREE: string
  SITE_URL: string
  SITE_ADDRESS: string
  SITE_ADDRESS_TWO: string
  SITE_ADDRESS_THREE: string
  SITE_PHONE: string
  SITE_SCHEDULE: string
  SITE_SCHEDULE_MOBILE: string
  PRODUCT_1: string
  SUBSCRIPTION_TYPE_1: string
  SUBSCRIPTION_TYPE_2: string
  SUBSCRIPTION_TYPE_3: string
  SUBSCRIPTION_INITIAL_TRIAL_PERIOD_DAYS: number
  INITIAL_PERIOD_FEE: string
  SUBSCRIPTION_PERIOD_DAYS: number
  PRODUCT_1_PRICE: string
  PRICE_SUBSCRIPTION_TYPE_1: string
  PRICE_SUBSCRIPTION_TYPE_2: string
  PRICE_SUBSCRIPTION_TYPE_3: string
  MOR_REGISTRY: string
  FEE_CURRENCY: string
  MONTH_YEAR: string
}

interface DataDogConfig {
  APPLICATION_ID: string
  CLIENT_TOKEN: string
}

interface AmplitudeConfig {
  KEY: string
}

interface FreschatConfig {
  ENABLED: boolean
  SRC: string
  WIDGET_ID: string
  TOKEN: string
  HOST: string
}

interface AwsConfig {
  TARGET_BUCKET_NAME: string
  HAS_TARGET_BUCKET_NAME: boolean
  AWS_REGION: string
  BUCKET_PREFIX: string
}

interface GoogleConfig {
  RECAPTCHA_SITE_KEY: string
  TAG_MANAGER_ID: string
}

interface LanguageSelectorConfig {
  ENABLED: boolean
  US_HOME_URL: string
  GB_HOME_URL: string
}

export interface IConfig {
  TARGET_ADDRESS: string
  APP_SUBDOMAIN: string
  DOMAIN: string
  LOGO_PATH: string
  PRODUCTION_VIEW_MODE: boolean
  HAS_COOKIE_MESSAGE: boolean
  IS_JONSNOW: boolean
  HAS_TRUSTPILOT: boolean
  HAS_GRAV_SITEMAP: boolean
  GATSBY_ACTIVE_ENV: string
  HAS_SUBCATEGORY_PAGE: boolean
  NODE_ENV: string
  API_CONFIG: ApiConfig
  SITE_METADATA: SiteMetadata
  DATADOG_CONFIG: DataDogConfig
  AMPLITUDE_CONFIG: AmplitudeConfig
  FRESHCHAT_CONFIG: FreschatConfig
  STRAPI_CONFIG: StrapiConfig
  AWS_CONFIG: AwsConfig
  GOOGLE_CONFIG: GoogleConfig
  LANGUAGE_SELECTOR_CONFIG: LanguageSelectorConfig
}

export function getConfigs(): IConfig {
  return {
    HAS_COOKIE_MESSAGE: process.env.HAS_COOKIE_MESSAGE === 'true',
    TARGET_ADDRESS: process.env.TARGET_ADDRESS ?? 'http://legal-contracts.com',
    APP_SUBDOMAIN: process.env.APP_SUBDOMAIN ?? 'http://app.legal-contracts.com',
    DOMAIN: process.env.DOMAIN ?? 'localhost',
    LOGO_PATH: process.env.LOGO_PATH ?? '/images/logos/lawdistrict-com',
    PRODUCTION_VIEW_MODE: process.env.PRODUCTION_VIEW_MODE === 'true',
    IS_JONSNOW: process.env.IS_JONSNOW === 'true',
    HAS_SUBCATEGORY_PAGE: process.env.HAS_SUBCATEGORY_PAGE === 'true',
    HAS_TRUSTPILOT: process.env.HAS_TRUSTPILOT === 'true',
    HAS_GRAV_SITEMAP: process.env.HAS_GRAV_SITEMAP === 'true',
    GATSBY_ACTIVE_ENV: process.env.GATSBY_ACTIVE_ENV ?? 'development',
    NODE_ENV: process.env.NODE_ENV ?? 'development',
    API_CONFIG: {
      PREFIX: process.env.API_PREFIX ?? 'https://local-api.legal-contracts.com/api',
      SECRET_KEY: process.env.HTTP_SECRET_KEY ?? ''
    },
    SITE_METADATA: {
      COMPANY_NAME: process.env.COMPANY_NAME ?? '',
      COMPANY_NAME_TWO: process.env.COMPANY_NAME_TWO ?? '',
      COMPANY_NAME_THREE: process.env.COMPANY_NAME_THREE ?? '',
      SITE_EMAIL: process.env.SITE_EMAIL ?? 'info@lawdistrict.com',
      SITE_NAME: process.env.SITE_NAME ?? 'LawDistrict',
      SITE_CIF: process.env.SITE_CIF ?? '',
      SITE_EIN_TWO: process.env.SITE_EIN_TWO ?? '',
      SITE_EIN_THREE: process.env.SITE_EIN_THREE ?? '',
      SITE_URL: process.env.SITE_URL ?? 'lawdistrict.com',
      SITE_ADDRESS: process.env.SITE_ADDRESS ?? 'Avinguda Litoral nº12-14, Planta 5, 08005 Barcelona, Spain',
      SITE_ADDRESS_TWO: process.env.SITE_ADDRESS_TWO ?? '6630 STIRLING RD, Hollywood, FL 33024 (US)',
      SITE_ADDRESS_THREE: process.env.SITE_ADDRESS_THREE ?? '2430 N 61 ST Avenue - Hollywood, Florida 33024 (US)',
      SITE_PHONE: process.env.SITE_PHONE ?? '',
      SITE_SCHEDULE: process.env.SITE_SCHEDULE ?? 'Contact Hours: Mon-Fri 8am - 10pm ET',
      SITE_SCHEDULE_MOBILE: process.env.SITE_SCHEDULE_MOBILE ?? 'Mon-Fri 8am - 5pm ET',
      PRODUCT_1: process.env.PRODUCT_1 ?? 'Single Document',
      PRODUCT_1_PRICE: process.env.PRODUCT_1_PRICE ?? '',
      SUBSCRIPTION_TYPE_1: process.env.SUBSCRIPTION_TYPE_1 ?? '7-Day Free Trial',
      SUBSCRIPTION_TYPE_2: process.env.SUBSCRIPTION_TYPE_2 ?? 'Monthly Plan',
      SUBSCRIPTION_TYPE_3: process.env.SUBSCRIPTION_TYPE_3 ?? 'Best Value',
      SUBSCRIPTION_INITIAL_TRIAL_PERIOD_DAYS: process.env.SUBSCRIPTION_INITIAL_TRIAL_PERIOD_DAYS
        ? +process.env.SUBSCRIPTION_INITIAL_TRIAL_PERIOD_DAYS
        : 7,
      INITIAL_PERIOD_FEE: process.env.INITIAL_PERIOD_FEE ?? '',
      SUBSCRIPTION_PERIOD_DAYS: process.env.SUBSCRIPTION_PERIOD_DAYS ? +process.env.SUBSCRIPTION_PERIOD_DAYS : 365,
      PRICE_SUBSCRIPTION_TYPE_1: process.env.PRICE_SUBSCRIPTION_TYPE_1 ?? '',
      PRICE_SUBSCRIPTION_TYPE_2: process.env.PRICE_SUBSCRIPTION_TYPE_2 ?? '',
      PRICE_SUBSCRIPTION_TYPE_3: process.env.PRICE_SUBSCRIPTION_TYPE_3 ?? '',
      MOR_REGISTRY: process.env.MOR_REGISTRY ?? '',
      FEE_CURRENCY: process.env.FEE_CURRENCY ?? 'USD',
      MONTH_YEAR: process.env.MONTH_YEAR ?? 'October, 2020'
    },
    DATADOG_CONFIG: {
      APPLICATION_ID: process.env.DATADOG_APPLICATION_ID ?? '',
      CLIENT_TOKEN: process.env.DATADOG_CLIENT_TOKEN ?? ''
    },
    AMPLITUDE_CONFIG: {
      KEY: process.env.AMPLITUDE_KEY ?? ''
    },
    FRESHCHAT_CONFIG: {
      ENABLED: process.env.FRESHCHAT === 'true',
      SRC: process.env.FRESHCHAT_SRC ?? '',
      WIDGET_ID: process.env.FRESHCHAT_WIDGET_ID ?? '',
      TOKEN: process.env.FRESHCHAT_TOKEN ?? '',
      HOST: process.env.FRESHCHAT_HOST ?? ''
    },
    STRAPI_CONFIG: {
      URL: process.env.STRAPI_URL ?? 'https://stage.strapi.lawdistrict.com/',
      TOKEN: process.env.STRAPI_TOKEN ?? '',
      DOMAIN: process.env.STRAPI_DOMAIN ?? 'LawDistrict',
      LOCALE: process.env.STRAPI_LOCALE ?? 'en-US'
    },
    AWS_CONFIG: {
      TARGET_BUCKET_NAME: process.env.TARGET_BUCKET_NAME ?? 'fake-bucket',
      HAS_TARGET_BUCKET_NAME: process.env.TARGET_BUCKET_NAME === 'seo-preview.1elegal.net',
      AWS_REGION: process.env.AWS_REGION ?? '',
      BUCKET_PREFIX: process.env.BUCKET_PREFIX ?? ''
    },
    GOOGLE_CONFIG: {
      RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY ?? '',
      TAG_MANAGER_ID: process.env.TAG_MANAGER_ID ?? ''
    },
    LANGUAGE_SELECTOR_CONFIG: {
      ENABLED: process.env.LANGUAGE_SELECTOR_ENABLED === 'true',
      US_HOME_URL: process.env.LANGUAGE_SELECTOR_US_HOME_URL ?? '/',
      GB_HOME_URL: process.env.LANGUAGE_SELECTOR_GB_HOME_URL ?? '/'
    }
  }
}
