const getItemsParamsUrl = ({ urlParams }) => {
  const params = {}

  const searchParams = new URLSearchParams(urlParams)

  searchParams.forEach(function (value, key) {
    params[key] = value
  })

  return params
}

const getParamsUrl = () => {
  let params = null
  if (typeof window !== 'undefined') {
    const windowLocationSearch = window.location.search
    if (windowLocationSearch) params = getItemsParamsUrl({ urlParams: windowLocationSearch })
  }
  return params
}

const existsParamInUrl = (item, itemsUrl = getParamsUrl()): boolean => {
  return !!itemsUrl?.[item]
}

const getParamUrlValue = (item, itemsUrl = getParamsUrl()) => {
  return itemsUrl?.[item]
}

export { getParamsUrl, existsParamInUrl, getParamUrlValue }
