import React, { useContext, useEffect, useState } from 'react'

import { CookieBarContext } from '../../../services/utils/CookieBarContext'
import { GetCustomerGeoLocation } from '@legal/customer'
import { ReferrerCookie } from '../../../services/storage/cookies/ReferrerCookie'
import { euCountries } from '@legal/shared/json/euCountries'

const MISSING_IP_COUNTRY = 'missingIPCountry'

export const CookiePolicyLayout: React.FC = (props) => {
  const cookieContextValue = useContext(CookieBarContext)
  const [acceptCookie, setAcceptCookie] = useState(cookieContextValue)

  const referrerCookie = new ReferrerCookie()

  useEffect(() => {
    referrerCookie.setReferrer()
  }, [])

  useEffect(() => {
    const IPCountry = localStorage.getItem('IPCountry')
    if (IPCountry) {
      handleCookieBarState(IPCountry)
    } else {
      GetCustomerGeoLocation()
        .then((response) => {
          let IPCountryValue = MISSING_IP_COUNTRY
          if (response.result.country_code) {
            IPCountryValue = response.result.country_code
          }
          localStorage.setItem('IPCountry', IPCountryValue)
          handleCookieBarState(IPCountryValue)
        })
        .catch(() => {
          handleCookieBarState(MISSING_IP_COUNTRY)
        })
    }
  }, [props.location])

  const handleCookieBarState = (IPCountry: string): void => {
    if (isCountryEuropean(IPCountry) || IPCountry === MISSING_IP_COUNTRY) {
      setAcceptCookie(acceptCookie !== null)
    }
  }

  const isCountryEuropean = (countryCode: string): boolean => {
    return euCountries[countryCode] !== undefined
  }

  return (
    <CookieBarContext.Provider value={acceptCookie}>
      <div className='snackbar-layout'>{props.children}</div>
    </CookieBarContext.Provider>
  )
}
