import { type PeriodTypes } from './date.types'

export const subtract = (date: Date, period: number, periodType: string): Date => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()
  const periodsType: PeriodTypes = {
    day: (periodDayToAdd: number) => new Date(year, month, day - periodDayToAdd),
    month: (periodMonthToAdd: number) => new Date(year, month - periodMonthToAdd, day),
    year: (periodYearToAdd: number) => new Date(year - periodYearToAdd, month, day)
  }
  return periodsType[periodType](period)
}
