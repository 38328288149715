import { type FormatDates, type Suffix } from './date.types'

const suffix: Suffix = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th'
}

const formats: FormatDates = {
  'MM/DD/YYYY': { day: '2-digit', month: '2-digit', year: 'numeric' },
  'MMMM Do, YYYY': { day: 'numeric', month: 'long', year: 'numeric' },
  'MMMM, YYYY': { month: 'long', year: 'numeric' }
}

export const formatDate = (date: Date, format: string): string => {
  const formatter = new Intl.DateTimeFormat(process.env.STRAPI_LOCALE, formats[format])
  const formattedDate = formatter.format(date)
  return formattedDate
}

const getSuffix = (date: Date): string => {
  const ordinalRule = new Intl.PluralRules(process.env.STRAPI_LOCALE, { type: 'ordinal' }).select(date.getDate())
  return suffix[ordinalRule]
}

export const formatDateWithSuffix = (date: Date, format: string): string => {
  const formattedDate = formatDate(date, format)
  const dateWithSuffix = formattedDate.replace(`${date.getDate()},`, `${date.getDate()}${getSuffix(date)},`)
  return dateWithSuffix
}
