import { ExtendedError } from '../extended-error/extended-error'
import { type HttpStatusCode } from './http-status-code'

export class HttpError extends ExtendedError {
  private readonly _result: object
  private readonly _status: HttpStatusCode

  constructor({ status, message, result }: { message: string; result: object; status: HttpStatusCode }) {
    super(`HTTP Error ${status}: ${message}`)

    this._result = result
    this._status = status
  }

  get result(): object {
    return this._result
  }

  get status(): HttpStatusCode {
    return this._status
  }
}
