import HttpClient, { type HttpClientResponse } from '@legal/core/http/http-client'
import { datadogLogs } from '@datadog/browser-logs'

import { getConfigs } from '@legal/core/configs'

export async function GetCustomerByToken(userToken: string): Promise<HttpClientResponse<unknown>> {
  const { API_CONFIG } = getConfigs()
  const http = HttpClient.create({
    baseUrl: API_CONFIG.PREFIX,
    defaults: {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    }
  })

  const myPromise = new Promise<HttpClientResponse<unknown>>((resolve, reject) => {
    http
      .get('customer')
      .then((response) => {
        datadogLogs.logger.info('GetCustomerByToken')
        resolve(response)
      })
      .catch((error: object | undefined) => {
        datadogLogs.logger.error('GetCustomerByToken', error)
        reject(error)
      })
  })

  return await myPromise
}
