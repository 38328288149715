/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'

import { clearCookie, getCookie, writeCookie } from './CookieManager'
import { COOKIE_NAME } from './CookieNames'

export const CookieContext = React.createContext<{
  cookie: Map<string, object | undefined>
  changeCookie: (cookieName: string, data?: object) => void
}>({
  cookie: new Map<string, object | undefined>(),
  changeCookie: () => {}
})

export const CookieContextProvider = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  const [cookie, setCookie] = React.useState<Map<string, object | undefined>>(
    new Map<string, object | undefined>(Object.entries(COOKIE_NAME).map(([key, value]) => [value, getCookie(value)]))
  )

  const changeCookie = (cookieName: string, newValue?: object): void => {
    if (!newValue) clearCookie(cookieName)
    writeCookie({ cookieName, data: newValue })
    const newCookie = new Map<string, object | undefined>(
      Object.entries(COOKIE_NAME).map(([key, value]) => [value, getCookie(value)])
    )
    setCookie(newCookie)
  }

  return <CookieContext.Provider value={{ cookie, changeCookie }}>{children}</CookieContext.Provider>
}
