class DataStore {
  protected readonly _data: {
    cookieName: string
    expirationDate?: Date
  }

  constructor({
    data
  }: {
    data: {
      cookieName: string
      expirationDate?: Date
    }
  }) {
    this._data = data
  }

  read(key: 'cookieName' | 'expirationDate'): string | Date | undefined {
    return this._data[key]
  }
}

export { DataStore }
